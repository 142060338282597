import React, { memo, useEffect, useState, useCallback, FormEvent } from 'react'
import useDisclosure from '../../hooks/useDisclosure'
import { useTextInput } from '../../hooks/useTextInput'
import { labels } from '../../labels'
import CheckBox from '../checkBox/CheckBox'
import OutlinedInput from '../outlinedInput/OutlinedInput'
import SvgIcon from '../svgIcon/SvgIcon'
import Text from '../text/Text'
import styles from './ingredients.module.css'
import Option from './Option'

interface IOptionsProps {
    name: string
    ingredientType: number | null
    onDeleteType: () => void
    getMaximum: (maximum: string) => void
    getRequired: (required: boolean) => void
    getOptions: (options: IEditIngredientOption[]) => void
    index: number
    haveError: string
    maxAmount?: string
    mandatory?: boolean
    ingredients?: IEditIngredientOption[]
}

type OptionState = IEditIngredientOption[]

const OptionsForEdit = ({
    index,
    name,
    onDeleteType,
    getMaximum,
    getRequired,
    getOptions,
    haveError,
    maxAmount,
    mandatory,
    ingredients,
    ingredientType,
}: IOptionsProps) => {
    const defaultOption: IngredientForType = {
        name: '',
        price: 0,
        deactive: false,
        ingredientType: ingredientType,
        maxAmount: 0,
    }
    const { open: required, handleToogle: toggleRequired } =
        useDisclosure(mandatory)
    const [options, setOptions] = useState<OptionState>(
        ingredients || [
            {
                ...defaultOption,
                id: Math.random() * 1000,
            },
        ]
    )
    const {
        text: maximum,
        handleChangeText: handleChangeMaximum,
        handleChangeString: handleChangeMaximumString,
    } = useTextInput(maxAmount || '0')

    const handleInfo = useCallback(
        (index: number, optionName: string, price: string) => {
            setOptions((prevState) => {
                const prevOptions = prevState.slice(0, index) || []
                const lastOptions =
                    prevState.slice(index + 1, prevState.length) || []
                return [
                    ...prevOptions,
                    {
                        ...prevState[index],
                        price: Number(price),
                        name: optionName,
                    },
                    ...lastOptions,
                ]
            })
        },
        []
    )
    const onToggleRequired = () => {
        if (!required) {
            handleChangeMaximumString('1')
        }
        toggleRequired()
    }

    const handleAddOption = () => {
        setOptions((prevState) => [
            ...prevState,
            { ...defaultOption, id: null },
        ])
    }

    const onDeleteOption = (index: number) => {
        setOptions((prevState) => {
            const prevOptions = prevState.slice(0, index) || []
            const lastOptions =
                prevState.slice(index + 1, prevState.length) || []
            return [
                ...prevOptions,
                { ...prevState[index], deactive: true },
                ...lastOptions,
            ]
        })
    }

    const onChangeMaximum = (event: FormEvent<HTMLInputElement>) => {
        handleChangeMaximum(event)
    }

    useEffect(() => {
        if (options.length === 0) handleAddOption()
    }, [])
    useEffect(() => {
        getRequired(required)
    }, [required])

    useEffect(() => {
        getMaximum(maximum)
    }, [maximum])

    useEffect(() => {
        getOptions(options)
    }, [options])

    return (
        <div>
            <div>
                <div className={styles.titleWrapper}>
                    <Text
                        label={name}
                        variant={`subtitle2 ${styles.textWidth}`}
                    />
                    <div className={styles.divider} />
                    <div className={styles.iconClick} onClick={onDeleteType}>
                        <SvgIcon iconName="trash" />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowInput}>
                        <CheckBox
                            checkId={`option-${index}`}
                            labelClassName="Caption3"
                            label={labels.requiredOption}
                            onClick={onToggleRequired}
                            checked={required}
                        />
                    </div>
                    <div className={styles.rowInput}>
                        <OutlinedInput
                            floatingLabel={labels.quantity}
                            type="text"
                            isNumber
                            error={
                                (!!haveError && !maximum && labels.required) ||
                                ''
                            }
                            value={maximum}
                            min="1"
                            onChange={onChangeMaximum}
                        />
                    </div>
                </div>
                {options.map(
                    (element, index) =>
                        !element.deactive && (
                            <Option
                                key={element.id}
                                haveError={haveError}
                                index={index}
                                deleteOption={() => onDeleteOption(index)}
                                handleInfo={handleInfo}
                                optionName={element.name}
                                optionPrice={`${element.price}`}
                            />
                        )
                )}
                <button
                    onClick={handleAddOption}
                    className={styles.addOptionButton}
                    type="button"
                >
                    <div>{labels.addOption}</div>
                    <div className="Body1-16">+</div>
                </button>
            </div>
        </div>
    )
}

export default memo(OptionsForEdit)
