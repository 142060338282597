import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../components/buttons/ButtonUI';
import OutlinedInput from '../../components/outlinedInput/OutlinedInput';
import './Login.css';
import Logo from '../../assets/icons/icons-logo.svg';
import TextLogin from '../../assets/icons/icon-text-login.svg';
import Text from '../../components/text/Text';
import { useLoadingContext } from '../../context/spinnerContext';
import { link } from './../../components/text/Text';
import routes from '../../router/routes';
import { loginUser } from '../../services/authentication';
import {
    useAuthDispatch,
    useAuthState,
} from '../../context/loginContext/loginContext';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../../components/modal/ModalComponent';
import IncorrectCredentials from '../../components/modal/modalVariants/IncorrectCredentials';
import { clevertapEvents, labels } from '../../labels';
import { format } from 'date-fns';

import {
    getBrands,
    getCategories,
    getCategoriesByCommerce,
} from '../../services/products';
import { logIn, track } from '../../ClevertapWrapper';
import { FeatureContext } from '../../context/useFeatureFlagContext';
import { getFeatureFlags } from '../../services/commerce';

interface ILoginFormErrors {
    commerceCode: string;
    password: string;
}

const formInputs: ILoginFormErrors = {
    commerceCode: 'commerceCode',
    password: 'password',
};

export default function Login() {
    const loadingContext = useLoadingContext();

    const { updateFeature } = useContext(FeatureContext);

    const [errors, setErrors] = useState<ILoginFormErrors>({
        commerceCode: '',
        password: '',
    });
    const dispatch = useAuthDispatch();
    const navigate = useNavigate();
    const user = useAuthState();

    const [loginCount, setLoginCount] = useState<number>(0);
    const [modalContentType, setModalContentType] = React.useState<ContentType>(null);
    const [isPilotModalVisible, setIsPilotModalVisible] = useState<boolean>(false);
    const [lat, setLat] = useState<number>(0);
    const [lng, setLng] = useState<number>(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const formDataRef = useRef<FormData | null>(null);
    const responseDataRef = useRef<ICommerceBO | null>(null);

    const data1 = {
        success: true,
    };
    const data2 = {
        error: true,
    };
    const noLoginData = {
        pageName: 'Login',
        pageURL: routes.login,
        geoLocationLat: lat,
        geoLocationLong: lng,
        date: format(new Date(), 'yyyy/MM/dd'),
        hour: format(new Date(), 'kk:mm:ss'),
    };
    const getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        });
    };
    const handleOpenErrorModal = (type: ContentType) => {
        setModalContentType(type);
    };

    const handleCloseErrorModal = () => {
        setModalContentType(null);
    };

    const handlePilotDecision = (redirect: boolean) => {
        setIsPilotModalVisible(false);
        if (redirect) {
            window.location.href = 'https://amiposweb.amipos.com';
        } else {
            loadingContext?.onClose();
            continueLoginFlow();
        }
    };

    const continueLoginFlow = async () => {
        loadingContext?.onOpen();
        if (responseDataRef.current && formDataRef.current) {
            const localData = JSON.stringify(responseDataRef.current);
            localStorage.setItem('currentUser', localData);
            localStorage.setItem('commerceCode', formDataRef.current.get('commerceCode') as string);
    
            await getBrands();
            await getCategories();
            await getCategoriesByCommerce();
    
            getFeatureFlags().then((res) => {
                updateFeature(res);
                loadingContext?.onClose();
                location.href = routes.home;
            });
        }
    };
    
    

    const formValidation = (data: FormData) => {
        let isValid = true;
        if (!data.get(formInputs.commerceCode)) {
            setErrors((prevState) => ({
                ...prevState,
                [formInputs.commerceCode]: 'Codigo de comercio requerido',
            }));
            isValid = false;
        }
        if (!data.get(formInputs.password)) {
            setErrors((prevState) => ({
                ...prevState,
                [formInputs.password]: 'Clave requerida',
            }));
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch({
            type: 'REQUEST_LOGIN',
            error: '',
            payload: { user: null, auth_token: '' },
        });
        const data = new FormData(event.currentTarget);
        formDataRef.current = data;
        const geo = navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position);
        });
        const loginDate = new Date();

        if (formValidation(data)) {
            loadingContext?.onOpen();
            await loginUser(
                data.get('commerceCode') as string,
                data.get('password') as string
            ).then(async (response) => {
                if (response.status === 200) {
                    logIn({
                        Site: {
                            geolocation: geo,
                            login: loginDate,
                        },
                    });
                    track('web- Sesión iniciada exitosamente', data1);
                    const parsedResponseData: ICommerceBO = JSON.parse(
                        await response.text()
                    );
                    responseDataRef.current = parsedResponseData;
                    const isPilot = parsedResponseData.cLocal.some((local) => local.isPilot);

                    if (isPilot) {
                        loadingContext?.onClose();
                        setIsPilotModalVisible(true);
                    } else {
                        continueLoginFlow();
                    }
                } else {
                    loadingContext?.onClose();
                    track('web- Error al iniciar sesión', data2);
                    setLoginCount((prevState) => prevState + 1);
                    handleOpenErrorModal('error');
                }
            });
        }
    };

    useEffect(() => {
        if (loginCount > 3) {
            setLoginCount(1);
        }
    }, [loginCount]);

    useEffect(() => {
        if (user.token) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        getLocation();
    }, [lat, lng]);

    useEffect(() => {
        track(clevertapEvents.pageLoadNoLogin, noLoginData);
    }, []);

    const handlePressForgotPassword = () => {
        track("web- Click en 'Olvide Contraseña'", data1);
    };

    return (
        <div className="background-pw">
            <ModalComponent
                show={!!modalContentType}
                handleClose={handleCloseErrorModal}
            >
                <IncorrectCredentials
                    text={
                        loginCount < 3
                            ? labels.incorrectCredentialsModal
                            : labels.accountBlockedModal
                    }
                    onClick={handleCloseErrorModal}
                />
            </ModalComponent>
            <ModalComponent
                show={isPilotModalVisible}
                handleClose={() => handlePilotDecision(false)}
                modalStyle='modalStyleContainer'
                icon={false}
            >
                <div className="pilotModalContent">
                    <p>Estás participando del piloto para el nuevo Amipos web. </p>
                    <p>¿Deseas permanecer en el Amipos actual o ser redirigido al nuevo Amipos web?</p>
                    <div className="pilotModalButtons">
                        <Button
                            label="Permanecer"
                            onClick={() => handlePilotDecision(false)}
                            variant="form"
                            type="button"
                        />
                        <Button
                            label="Redirigirme"
                            onClick={() => handlePilotDecision(true)}
                            variant="form"
                            type="button"
                        />
                    </div>
                </div>
            </ModalComponent>
            <div className="loginContainer">
                <div className="login">
                    <form
                        className="loginform"
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <img src={Logo} alt="logo" />
                        <img src={TextLogin} className="textLogoStyle" />
                        <OutlinedInput
                            floatingLabel={labels.commerceCode}
                            placeholder="000000"
                            formId="commerceCode"
                            error={errors.commerceCode}
                        />
                        <OutlinedInput
                            floatingLabel={labels.password}
                            placeholder="*******"
                            formId="password"
                            type="password"
                            trailingIcon="show"
                            error={errors.password}
                        />
                        <Button
                            label={labels.login}
                            type="submit"
                            variant="form"
                        />
                    </form>
                    <section className="bottomLinks">
                        <div onClick={handlePressForgotPassword}>
                            <Text
                                as={link}
                                to={routes.passwordRecovery}
                                variant="subtitle1 noUnderline bottomLinksColor"
                                label={labels.forgetPassword}
                            />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
